// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 30px;
  background-color: black;
  color: white;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contactInfo, .hours, .socialLinks {
  width: 30%; /* Adjust this to fit your design */
  margin-bottom: 20px;
}

.hours h3, .contactInfo h3, .socialLinks h3 {
  margin-bottom: 10px;
}

.footerNote {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

/* Adjust the layout for smaller screens */
@media (max-width: 768px) {
  .contactInfo, .hours, .socialLinks {
    width: 100%;
    text-align: center;
  }

  .footerContent {
    flex-direction: column;
    align-items: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,UAAU,EAAE,mCAAmC;EAC/C,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA,0CAA0C;AAC1C;EACE;IACE,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB;AACF","sourcesContent":[".footer {\n  padding: 30px;\n  background-color: black;\n  color: white;\n}\n\n.footerContent {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.contactInfo, .hours, .socialLinks {\n  width: 30%; /* Adjust this to fit your design */\n  margin-bottom: 20px;\n}\n\n.hours h3, .contactInfo h3, .socialLinks h3 {\n  margin-bottom: 10px;\n}\n\n.footerNote {\n  text-align: center;\n  margin-top: 20px;\n  font-size: 14px;\n}\n\n/* Adjust the layout for smaller screens */\n@media (max-width: 768px) {\n  .contactInfo, .hours, .socialLinks {\n    width: 100%;\n    text-align: center;\n  }\n\n  .footerContent {\n    flex-direction: column;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
